import settings from './global.config.js';

// See: https://github.com/github/fetch#handling-http-error-statuses
export function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}

	let error = new Error(response.statusText);
	error.response = response;
	throw error;
}

function fetchConfigDevelopment(config) {
	config
		.withBaseUrl(settings.baseUrl)
		.withDefaults({
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			}
		});
}


export function fetchConfigGeo(config) {
	config
		.withBaseUrl(settings.baseUrl)
		.withDefaults({
			headers: {
				'Accept': 'application/json'
			}
		});
	
}


export function reset(config){
	
	if (window.location.hostname === 'localhost') {
		fetchConfigDevelopment(config);
	} else {
		fetchConfigProduction(config);
	}
}

function fetchConfigProduction(config) {
	config
		.withBaseUrl(settings.baseUrl)
		.withDefaults({
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			}
		});
}

let config;


if (window.location.hostname === 'localhost') {
	config = fetchConfigDevelopment;
} else {
	config = fetchConfigProduction;
}
export default config;


