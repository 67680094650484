export class IterateObjectValueConverter {
  toView(value) {
		if (value == null)
			return [];
    var result = [];
    for (let key in value) {
      result.push([key, value[key]]);
    }
    return result;
  }
}
