/* eslint-disable no-var */
var settings = {
	project: 'WITHcrowd',
	space: 'espace',
	auth: {
		google: '104762619694-41avdtg6stm15vrgv511fa1spb6m8282.apps.googleusercontent.com',
		facebook: '2073345106292490'
	},
	baseUrl: 'https://api.crowdheritage.eu',						 // Production backend
	apiUrl: '/assets/developers-lite.html',
	googlekey: 'AIzaSyAdgFjsGN9VmFLGQlAdRYaz0REwyvjn6eg',
  logLevel: 1 																				 // Error: 1, Warn: 2, Info: 3, Debug: 4
};

// Override settings for development/testing etc
if (window.location.hostname === 'localhost') {
	// settings.baseUrl = 'https://api.withculture.eu';  // Original WITH-backend
	// settings.baseUrl = 'https://api.withcrowd.eu';    // Backend with test-DB
	// settings.baseUrl = 'http://localhost:9060';       // Local backend for testing
	// settings.project = 'WITHcrowd';
	settings.project = 'CrowdHeritage';
	settings.logLevel = 4; 															 // Debug
}
// Override for staging
else if (window.location.hostname === 'withcrowd.eu') {
	settings.auth.facebook='290698945139676';
	settings.baseUrl = 'https://api.withcrowd.eu';
}
else if (window.location.hostname === 'crowdheritage.eu' || window.location.hostname === 'www.crowdheritage.eu') {
	settings.auth.facebook='2727958063912119';
	settings.project = 'CrowdHeritage';
}
else {
  console.log(`${window.location.hostname}`);
}

export default settings;
