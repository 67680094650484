/* eslint-disable */
import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import fetchConfig from 'fetch.config.js';
import { checkStatus, fetchConfigGeo, reset } from 'fetch.config.js';

@inject(HttpClient)
export class ThesaurusServices {

  constructor(http) {
    http.configure(fetchConfig);
  	this.http = http;
  }

  // word is the prefix of the label you want to return
  // vocabularies is an array of prefixes from which thesauruses you want to have suggestions
  async getSuggestions(word, vocabularies ) {
	let vocabs = vocabularies.join( "," );
    return this.http.fetch(`/thesaurus/suggestions?word=${word}&namespaces=${vocabs}`, {
      method: 'GET'
    }).then(checkStatus).then((response) => {
      return response.json();
    });
  }

  async getCampaignSuggestions(word, campaignId, lang="all") {
		return this.http.fetch(`/thesaurus/suggestions?word=${word}&campaignId=${campaignId}&language=${lang}`, {
	      method: 'GET'
	    }).then(checkStatus).then((response) => {
	      return response.json();
	    });
	  }

  async getGeonameSuggestions(prefix ) {
	  this.http.configure(
	        fetchConfigGeo);
	  return this.http.fetch("https://secure.geonames.org/searchJSON?q=" +  encodeURIComponent(prefix)  + "&username=annachristaki&maxRows=10", {
	      method: 'GET'
	    }).then((response) => {
	      this.http.configure(reset);
	      return response.json();

	    });
	  }

}
