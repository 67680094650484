import settings from './global.config.js';

export default {
	baseUrl: settings.baseUrl,
	signupUrl: '/user/register',
	loginUrl: '/user/login',
	profileUrl: '/user/me',
	token_prefix: 'WITH',
	accessTokenName: 'dbId',
	loginRedirect: '#/my',
	providers: {
		google: {
			clientId: settings.auth.google,
			url: '/user/googleLogin'
		},
		facebook: {
			clientId: settings.auth.facebook,
			url: '/user/facebookLogin'
		}
	}
};
