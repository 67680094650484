import { inject } from 'aurelia-framework';
import { Campaign } from 'Campaign.js';
import { CampaignServices } from 'CampaignServices.js';
import { UserServices } from 'UserServices.js';
import { Record } from 'Record.js';
import { RecordServices } from 'RecordServices.js';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import settings from 'global.config.js';

let COUNT = 7;

@inject(CampaignServices, UserServices, RecordServices, Router, I18N, 'isTesterUser')
export class CampaignIndex {
  scrollTo(anchor) {
    $('html, body').animate({
      scrollTop: $(anchor).offset().top
    }, 1200);
  }

  constructor(campaignServices, userServices, recordServices, router, i18n, isTesterUser) {
    this.campaignServices = campaignServices;
    this.userServices = userServices;
    this.recordServices = recordServices;
    this.router = router;
    this.isTesterUser = isTesterUser();

    this.project = settings.project;

    this.campaigns = [];
    this.campaignsCount = 0;
    this.currentCount = 0;
    this.loading = false;
    this.more = true;
    this.groupName = "";
    this.sortBy = "Date";
    this.state = "all";

    this.i18n = i18n;
    this.currentLocaleCode = this.i18n.getLocale();
  }

  attached() {
    $('.accountmenu').removeClass('active');
  }

  get isAuthenticated() { return this.userServices.isAuthenticated(); }

  activate(params) {
    // If no language is specified, redirect to the English page by default
    if (params.lang == undefined) {
      this.router.navigate("en");
    }
    // Set the page locale
    this.i18n.setLocale(params.lang);
    this.currentLocaleCode = this.i18n.getLocale();

    if (this.userServices.isAuthenticated() && this.userServices.current === null) {
      this.userServices.reloadCurrentUser();
    }
    this.campaignServices.getCampaignsCount("", this.project, this.state)
      .then( result => {
        this.campaignsCount = result;
      });
    this.getCampaigns("", this.sortBy, this.state);
  }

  getCampaigns(groupName, sortBy, state) {
	  this.campaigns = [];

    this.loading = true;
    this.campaignServices.getCampaigns( {group: groupName, project: this.project, state: state, sortBy: sortBy, offset: 0, count: COUNT} )
      .then( (resultsArray) => {
        if (this.loading) {
          this.fillCampaignArray(this.campaigns, resultsArray);
          this.currentCount = this.currentCount + resultsArray.length;
          if (this.currentCount >= this.campaignsCount) {
            this.more = false;
          }
          this.loading = false;
        }
      });
  }

  fillCampaignArray(campaignArray, results) {
    let localIndex = 0;
		for (let item of results) {
      // Based on the selected language, set the campaign
      let camp = new Campaign(item, this.currentLocaleCode);
      // Keep the active campaigns at the beginning of the list
      if ( (this.state == "all") && (camp.status == "active") ) {
        campaignArray.splice(localIndex, 0, camp);
        localIndex++;
      }
      else {
        campaignArray.push(camp);
      }
		}
  }

  loadMore() {
    this.loading = true;
    this.campaignServices.getCampaigns( {group: this.groupName, project: this.project, state: this.state, sortBy: this.sortBy, offset: this.currentCount, count: COUNT} )
      .then( (resultsArray) => {
        this.fillCampaignArray((this.campaigns), resultsArray);
        this.currentCount = this.currentCount + resultsArray.length;
        if (this.currentCount === this.campaignsCount) {
          this.more = false;
        }
      });
    this.loading = false;
  }

  goToRandomItem(camp, col, records, offset) {
    let item = this.router.routes.find(x => x.name === 'item');
    let recs = [];
    item.campaign = camp;
    item.collection = 0;
    item.offset = offset;

    // Get 2 random records to start annotating
    this.loading = true;
    this.recordServices.getRandomRecordsFromCollections(camp.targetCollections, 2)
      .then(response => {
        if (response.length>0) {
          for (let i in response) {
            let result = response[i];
            if (result !== null) {
              let record = new Record(result, camp.username === 'garment-type' ? true : false);
              recs.push(record);
            }
          }
          this.loading = false;
          item.records = recs;
          this.router.navigateToRoute('item', {cname: camp.username, lang: this.currentLocaleCode, recid: recs[0].dbId});
        }
        })
      .catch(error => {
        this.loading = false;
        console.log(error.message);
      });
  }

  toggleSortMenu() {
    if ($('.sort').hasClass('open')) {
      $('.sort').removeClass('open');
    }
    else {
      $('.sort').addClass('open');
    }
  }

  toggleStateMenu() {
    document.getElementById("state-menu").classList.toggle("open");
  }

  reloadCampaigns(state, sortBy) {
    if ( (state==this.state) && (sortBy==this.sortBy) ) {
			return;
		}
		else {
      this.campaigns.splice(0, this.campaigns.length);
      this.currentCount = 0;
      this.more = true;
      this.sortBy = sortBy;
      this.state = state;
      this.campaignServices.getCampaignsCount("", this.project, this.state)
        .then( result => {
          this.campaignsCount = result;
          this.getCampaigns(this.groupName, this.sortBy, this.state);
        });
      }
  }

}
