export class CampaignUsernameTrimValueConverter {
  toView(value) {
		if (value == undefined)
			return "campaign";
		if (value.length <= 11)
			return value;
		value = value.split('-')[0];
		if (value.length <= 11)
			return value;
    return value.substring(0, 11);
  }
}
