import { noView } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@noView
export class CampaignCounterValueConverter {
	static inject = [I18N];
	constructor(i18n) {
		this.i18n = i18n;
	}
	toView(value, i18n) {
		let camp = this.i18n.tr('app:campaign');
		let camps = this.i18n.tr('app:campaigns');
		return value == 1 ? value+' ' +camp : value+' ' +camps;
	}
}
